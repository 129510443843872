/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import useDeviceType from '../../../../hooks/layout/useDeviceType'
import { useNativeAdsState } from '../../../../selectors/ads'
import Icon from '../../../Icon/Icon.component'
import CloseCircle from '../../../Icon/icons/CloseCircle'
import { InjectHtml } from '../components/InjectHtml'
import { Invisible } from '../components/Invisible'
import { TrackingPixel } from '../components/TrackingPixel'
import {
    ParsedRevealerData // useRevealerMockData
} from './Revealer.helpers'
import { CLEAR_REVEALER_CALLBACK_KEY, INJECTED_REVEALER_SCRIPTS_VARIABLE_NAME } from './Revealer.provider'
import RevealerStyled, { StyledCloseButton } from './Revealer.style'
import RevealerCreative from './RevealerCreative.component'

const Revealer = ({ children }: PropsWithChildren<never>) => {
    const revealerData = useNativeAdsState('revealer') as ParsedRevealerData

    // const RevealerMockData = useRevealerMockData()
    const { creative, ad, dimensions, clickOptions, open } = revealerData || {}
    const [canOpen, setCanOpen] = useState(false)

    const numOfTiles = 20

    const onCloseCallback = useCallback(() => {
        if (typeof window === 'undefined') return
        window?.[CLEAR_REVEALER_CALLBACK_KEY]?.()
        setCanOpen(false)
    }, [])
    const [, , isMobile] = useDeviceType()
    const hasRevealer = !!ad && !!creative && !!isMobile && !!clickOptions && !!dimensions
    const isOpen = !!hasRevealer && !!open && !!isMobile && !!canOpen

    // Close the revealer after 7 seconds + 1 second for the open/close animation
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                onCloseCallback()
            }, 8000)
            return () => clearTimeout(timer)
        }
        return undefined
    }, [isOpen, onCloseCallback])

    return (
        <>
            <RevealerStyled
                dimensions={dimensions}
                creative={creative}
                onClick={onCloseCallback}
                numOfTiles={numOfTiles}>
                {hasRevealer && (
                    <div className="Revealer_container">
                        <div className="Revealer_content">
                            <RevealerCreative
                                ad={ad}
                                creative={creative}
                                clickOptions={clickOptions}
                                dimensions={dimensions}
                                numOfTiles={numOfTiles}
                                onLoad={() => {
                                    setCanOpen(true)
                                }}
                            />
                            <StyledCloseButton onClick={onCloseCallback}>
                                {/* @ts-expect-error: js component prop types are not being detected properly */}
                                <Icon icon={CloseCircle} size={35 * 1.3} />
                            </StyledCloseButton>
                        </div>
                    </div>
                )}
                <div className={clsx('Content_container', isOpen && 'is_open')}>{children}</div>
            </RevealerStyled>
            {hasRevealer && (
                <Invisible>
                    {/* Impression tracking via pixels */}
                    <TrackingPixel src={clickOptions?.viewUrlUnesc} />
                    <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} />
                    {/* Impression tracking via html*/}
                    {clickOptions?.thirdPartyTagsHtml && (
                        <InjectHtml
                            windowKey={INJECTED_REVEALER_SCRIPTS_VARIABLE_NAME}
                            html={clickOptions?.thirdPartyTagsHtml}
                        />
                    )}
                </Invisible>
            )}
        </>
    )
}

export default Revealer
