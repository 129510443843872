import { useAdElementHref } from '../hooks/useAdElementHref'
import { ParsedRevealerData } from './Revealer.helpers'

const RevealerCreative = ({
    creative,
    ad,
    clickOptions,
    numOfTiles,
    onLoad
}: {
    creative: NonNullable<ParsedRevealerData['creative']>
    ad: NonNullable<ParsedRevealerData['ad']>
    clickOptions: NonNullable<ParsedRevealerData['clickOptions']>
    dimensions: NonNullable<ParsedRevealerData['dimensions']>
    numOfTiles: number
    onLoad?: () => void
}) => {
    const href = useAdElementHref(ad, clickOptions)
    const { openInNewTab } = clickOptions
    const { fileImage } = creative
    const src = fileImage

    if (!src) return null

    return (
        <>
            <img
                src={src}
                style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
                width="1"
                height="1"
                onLoad={() => {
                    onLoad?.()
                }}
            />
            <a
                id="revealer_creative"
                className="container is_active"
                href={href}
                target={openInNewTab ? '_blank' : '_self'}>
                {Array.from({ length: numOfTiles }, (v, i) => (
                    <div key={`tile_${i}`} className="tile" />
                ))}
            </a>
        </>
    )
}
export default RevealerCreative
