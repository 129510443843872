/* eslint-disable @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'
import type { ParsedRevealerData } from './Revealer.helpers'

export type StyleProps = {
    theme?: any
    creative: ParsedRevealerData['creative']
    dimensions?: ParsedRevealerData['dimensions']
    numOfTiles: number
}

// dynamically generate nth-child styles
const tileNthChildStyles = (tilesNum: number): Record<string, any> => {
    return Array.from({ length: tilesNum }).reduce<Record<string, any>>((acc, _, i) => {
        const index = i + 1
        acc[`&:nth-of-type(${index})`] = {
            backgroundPosition: `50% calc(${-i * (100 / tilesNum)}vh + 10px)`
        }
        return acc
    }, {})
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = ({ theme, creative, dimensions, numOfTiles }: StyleProps) => {
    const { fileImage, imageBackgroundColor } = creative || {
        fileImage: '',
        imageBackgroundColor: ''
    }

    return {
        position: 'relative',
        height: 'auto',
        '.Revealer': {
            '&_container': {
                position: 'absolute',
                marginTop: -2,
                left: 0,
                width: '100vw',
                height: '100vh'
            },

            '&_content': {
                position: 'fixed',
                height: '100vh',
                width: '320px',
                right: 0,
                // top: 60,

                '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '50px',
                    backgroundColor: `${imageBackgroundColor}`,
                    top: '-50px'
                }
            }
        },
        '.Content_container': {
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                transition: 'transform 0.5s ease-in-out'
                // INFO: initial transform coliides with position: fixed, you dont need it
                // transform: 'translateX(0px)'
            },
            '&.is_open': {
                [theme.breakpoints.down('sm')]: {
                    transition: 'transform 0.3s ease-in-out',
                    transform: 'translateX(-320px)'
                }
            }
        },

        '.container': {
            position: 'absolute',
            display: 'block',
            width: '320px',
            height: '100%',
            border: '0px',
            pointerEvents: 'all'
        },

        '.tile': {
            width: '100%',
            height: `${100 / numOfTiles}%`,
            background: `url(${fileImage}) no-repeat center center`,
            backgroundColor: `${imageBackgroundColor}`,
            // backgroundColor: 'red',
            // backgroundSize: 'min(138%, 45vh)',
            backgroundSize: '300px',
            pointerEvents: 'none',

            ...tileNthChildStyles(numOfTiles)
        }
    }
}
const Styled = createStyledComponent({
    tag: 'div',
    styles,
    blockProps: ['height', 'width', 'dimensions']
})
export default Styled

type CloseButtonProps = {
    theme?: any
    size?: number
}

export const StyledCloseButton = createStyledComponent({
    styles: ({ size = 30 }: CloseButtonProps) => {
        const offset = size / 2

        return {
            pointerEvents: 'all',

            position: 'absolute',
            top: offset,
            right: offset,
            zIndex: 10004,

            width: size + 'px',
            height: size + 'px'
        }
    },
    tag: 'button',
    forwardProps: ['onClick']
})
